import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Header } from '@opengov/component-library/capital';
import { FigmaEmbed, PatternExample, UsageBlock } from '../../../components';
import LayoutComponents from '../../../components/capital/Layouts/LayoutComponents';
export const _frontmatter = {
  "title": "Header",
  "componentId": "header",
  "description": "Headers are used to create headings within the page using html header tags.",
  "activeTab": "components"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = LayoutComponents;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Please visit the `}<strong parentName="p"><a parentName="strong" {...{
          "href": "/styles/typography"
        }}>{`Typography`}</a></strong>{` page for more information on using the Header component.`}</p>
    <p><strong parentName="p">{`Documentation in progress------------------------`}</strong></p>
    <h2>{`Variants`}</h2>
    <PatternExample example={<Header variant="hero" mdxType="Header">Hero Header</Header>} title="Hero" mdxType="PatternExample" />
    <PatternExample example={<Header variant="h1" mdxType="Header">Header 1</Header>} title="H1" mdxType="PatternExample" />
    <PatternExample example={<Header variant="h2" mdxType="Header">Header 2</Header>} title="H2" mdxType="PatternExample" />
    <PatternExample example={<Header variant="h3" mdxType="Header">Header 3</Header>} title="H3" mdxType="PatternExample" />
    <PatternExample example={<Header variant="h4" mdxType="Header">Header 4</Header>} title="H4" mdxType="PatternExample" />
    <PatternExample example={<Header variant="h5" mdxType="Header">Header 5</Header>} title="H5" mdxType="PatternExample" />
    <h2>{`Configuration`}</h2>
    <PatternExample example={<Header variant="h3" as="h2" mdxType="Header">Example header</Header>} title="Example h3 style header as h2" mdxType="PatternExample" />
    <h2>{`Accessibility`}</h2>
    <p>{`Information about how to structure headers to ensure accessibility (thinking about levels).`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      